<!-- footer-->
<footer class="footer_top">
    <div class="container">
        <div class="row">
            <!-- Social Section-->
            <div class="col-md-3">
                <h3>FOLLOW US</h3>
                <ul class="social">
                    <li class="twitter"><span><i class="fa fa-twitter"></i></span><a target="_blank"
                            href="https://twitter.com/proxy_tech">Twitter</a></li>
                    <li class="linkedin"><span><i class="fa fa-linkedin"></i></span><a target="_blank"
                            href="https://www.linkedin.com/company/tech-proxy">Linkedin</a></li>
                    <li class="github"><span><i class="fa fa-github"></i></span><a target="_blank"
                            href="https://github.com/tech-proxy">Github</a></li>
                </ul>
            </div>
            <!-- End Social Section-->
            <!-- Contact Us-->
            <div class="col-md-3">
                <h3>CONTACT US</h3>
                <ul class="contact_footer">
                    <li>
                        <i class="fa fa-envelope"></i> <a target="_blank"
                            href="mailto:onur@techproxy.co.uk">onur@techproxy.co.uk</a>
                    </li>
                    <li class="location">
                        <i class="fa fa-home"></i> <a target="_blank" href="https://goo.gl/maps/URGiKafp5ZTSYB8M6">
                            17 Green Lanes, London, England, N16 9BS</a>
                    </li>
                </ul>
            </div>
            <!-- Contact Us-->

            <div class="col-md-6">
                <h3>Get in touch</h3>


                <!-- Map area-->
                <section class="map_area">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.863737959937!2d-0.08866198422836591!3d51.552397579642516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761c86bd550397%3A0x1c93ed26bf9ef886!2s17%20Green%20Lanes%2C%20Newington%20Green%2C%20London%20N16%209BS!5e0!3m2!1sen!2suk!4v1620332565127!5m2!1sen!2suk"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </section>
                <!-- End Map area-->
            </div>
        </div>
    </div>
</footer>
<!-- End footer-->

<!-- footer-->
<footer class="coopring">
    <p>&copy; 2021 Tech Proxy. All Rights Reserved.</p>
</footer>
<!-- End footer-->