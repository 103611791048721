<!-- Header Section-->
<header class="index_static">
    <!-- nav_logo Section-->
    <div class="nav_logo animated fadeInDown">
        <div class="container">
            <div class="row">
                <!-- Logo-->
                <div class="col-md-3 logo">
                    <a href="/" title="Back to Home">
                        <img src="assets/img/logo.png" alt="Logo" class="logo_assets/img">
                    </a>
                </div>
                <!-- End Logo-->


            </div>
        </div>
    </div>
    <!-- End nav_logo Section-->

    <!-- End content info - Parallax Section 02 -->
    <section class="content_info">
        <!-- Parallax Background -->
        <div class="bg_parallax image_03_parallax"></div>
        <!-- Parallax Background -->

        <!-- Content Parallax-->
        <section class="opacy_bg_02 paddings">
            <!-- Info Head Static-->
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">

                        <br>
                        <h1 class="animated fadeInDown delay1">Software Design and <br>Development for<br>
                            <span>Web</span>
                        </h1>
                        <p class="animated fadeInUp delay1">Tailor-made Software Consultant Services</p>

                    </div>
                </div>
            </div>
            <!-- End Info Head Static-->
        </section>
        <!-- End Content Parallax-->
    </section>
    <!-- End content info - Parallax Section 02 -->
</header>
<!-- End Header Section-->