<div class="col-sm-6 col-md-3">
    <div class="service-01">
        <div class="head-service-01">
            <a [href]="ServiceItem.ServiceUrl" target="_blank" class="tooltip_hover" [title]="ServiceItem.ServiceName">
                <img style="max-width: 120px;" [src]="ServiceItem.ServiceLogoUrl" [alt]="ServiceItem.ServiceName"></a>

        </div>
        <div class="caption-service-01">
            <h3></h3>
            <p>{{ServiceItem.ServiceDescription}}</p>
        </div>
    </div>
</div>